import { PrivacyPolicyUI } from "features";

const PrivacyPolicy = () => {
  return (
    <>
      <PrivacyPolicyUI />
    </>
  );
};

export { PrivacyPolicy };
