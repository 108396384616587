import { GetInTouch } from "components";
import styles from "./styles.module.scss";

const PrivacyPolicyUI = () => {
  return (
    <>
      <section className={styles.containerBg}>
        <section className={`container ${styles.container}`}>
          <h1 className={styles.ttl}>Privacy Policy</h1>
          <section className={styles.body}>
            <div>
              <p className={styles.section__ttl}>INTRODUCTION</p>
              <p className={styles.section__txt1}>
                Welcome to Zitra Micro Finance Bank Limited (“Zitra”, “We”,
                “Our”, “Us”) Privacy Notice (“Notice”). We understand how
                important your personal information is to you, and are committed
                to protecting your personal data. This Notice covers the
                personal information Zitra collects or processes from its
                various sources. The information contained in this Notice are
                information we are required to provide to data subject by virtue
                of the Nigeria Data Protection Act, 2023 (“NDPA”). 
              </p>
              <p className={styles.section__txt2}>
                We urge you to read the whole Notice to understand how we
                process your personal information.
              </p>
            </div>
            <div>
              <p className={styles.section__ttl}>
                DATA PROTECTION OFFICER (DPO)
              </p>
              <p className={styles.section__txt2}>
                Zitra has in compliance with the NDPA appointed a Data
                Protection Officer to guide and manage our processing
                activities. If you have any question and issue regarding this
                Notice, kindly contact dpo@zitrabank.com
              </p>
            </div>
            <div>
              <p className={styles.section__ttl}>
                INFORMATION WE COLLECT ABOUT YOU
              </p>
              <p className={styles.section__txt2}>
                In connection with the services, we offer you, we will collect,
                use and store the following categories of personal information
                about you: 
              </p>
              <ul className={styles.section__list}>
                <li>
                  Account data: These are information we collect when you open
                  an account with us or sign up into our application. This
                  information includes your name, email address, phone number,
                  next of kin, passport, date of birth, country of origin,
                  location, house address, identification card such as national
                  identification number, bank verification number. We collect
                  this information in order to render our services to you.
                </li>
                <li>
                  Marketing/Communication: We use this data to provide
                  information about our services, new product, events and
                  webinar to you. We also use this information to communicate
                  with you in order to provide inquiry and support. The
                  information includes phone number, call log, email address and
                  email content, date of birth.
                </li>
                <li>
                  Technical data: When you visit our website our servers may
                  automatically log the standard data provided by your web
                  browser. It may include your device’s Internet Protocol (IP)
                  address, your browser type and version, the pages you visit,
                  the time and date of your visit, the time spent on each page,
                  the location and other details about your visit. We also
                  collect your personal data through closed circuit camera
                  (“CCTV”).
                </li>
                <li>
                  Other Data- we may collect data from you to be processed in
                  relation to administrative or judicial proceedings.
                </li>
              </ul>
            </div>
            <div>
              <p className={styles.section__ttl}>
                INFORMATION ABOUT OTHER PEOPLE
              </p>
              <p className={styles.section__txt2}>
                If you provide information to us about any person other than
                yourself, your employees, counterparties, your advisers or your
                suppliers, you must ensure that they understand how their
                information will be used, and that they have given their consent
                for you to disclose it to us and for you to allow us, and our
                outsourced service providers to use it. 
              </p>
            </div>
            <div>
              <p className={styles.section__ttl}>CHILDREN'S DATA</p>
              <p className={styles.section__txt2}>
                We do not aim any of our products or services directly at
                children or persons under the age of eighteen (18) years and we
                have put appropriate measure in place to block a person below
                the age of 18years from registering into our platforms. In the
                event that need arises for us to process personal information of
                a person below 18years we will obtain a verified consent of the
                parent or guardian as required by the NDPA.
              </p>
            </div>
            <div>
              <p className={styles.section__ttl}>COOKIES</p>
              <p className={styles.section__txt2}>
                We use cookies and other tracking technologies on our website.
                Cookies are small pieces of text that are saved on your Internet
                browser when you use our website. The cookies are sent back to
                our computer each time you visit our website. Cookies make it
                easier for us to give you a better experience online. You can
                stop your browser from accepting cookies, but if you do, some
                parts of our website or online services may not function
                appropriately. 
              </p>
            </div>
            <div>
              <p className={styles.section__ttl}>
                HOW IS YOUR PERSONAL INFORMATION COLLECTED?
              </p>
              <p className={styles.section__txt2}>
                We use different methods to collect personal information about
                your and they include:
              </p>
              <ul className={styles.section__list}>
                <li>
                  Direct interactions. These are information to provide to us
                  when you sign up for our services or products, communicate
                  with us or create wallet account with us.
                </li>
                <li>
                  Automated technological interactions. We collect these
                  personal information as you interact with our website or
                  application. These include personal data about your equipment,
                  browsing actions and patterns, location data. We collect this
                  personal data by using cookies, server logs and other similar
                  technologies. 
                </li>
                <li>
                  Third parties or publicly available sources. We may receive
                  personal information about you from various third parties and
                  public sources available to us such as your financial
                  institution, open government sources, agencies amongst others.
                </li>
              </ul>
            </div>
            <div>
              <p className={styles.section__ttl}>
                HOW WE USE YOUR PERSONAL DATA
              </p>
              <p className={styles.section__txt2}>
                We process your personal data for the following reasons:
              </p>
              <ul className={styles.section__list}>
                <li>To provide our services or products to you.</li>
                <li>To comply with our obligation under the law.</li>
                <li>
                  To resolve dispute resolution that may arise in the course of
                  providing services or products to you.
                </li>
                <li>To carry out know your customer (KYC) due diligence.</li>
                <li>To provide marketing communication to you.</li>
                <li>
                  To carry out credit assessment on you when you need our
                  service or product
                </li>
                <li>To ensure fraud prevention and investigation.</li>
                <li>To respond to your inquiries and correspondence.</li>
                <li>
                  To establish, defend, investigate and exercise our legal
                  right.
                </li>
                <li>For our own legitimate business interests.</li>
                <li>
                  We process your personal information in order to enhance
                  security, monitor and verify identity or service access,
                  combat other malware or security risks, and to comply with
                  applicable security laws and regulations in our jurisdiction.
                </li>
              </ul>
            </div>
            <div>
              <p className={styles.section__ttl}>FURTHER PURPOSE</p>
              <p className={styles.section__txt2}>
                We will only process your personal information for the
                particular purposes for which we collect it. We will notify you
                and rely on an appropriate lawful basis if we intend to use it
                for another purpose and such further purpose is not compatible
                with the original purpose.
              </p>
            </div>
            <div>
              <p className={styles.section__ttl}>
                LEGAL BASES FOR PROCESSING YOUR PERSONAL DATA
              </p>
              <p className={styles.section__txt2}>
                We may process your personal data on one or more lawful grounds
                depending on the specific purpose for which we are using your
                personal data. Kindly contact us if you need further details or
                clarity about the specific legal ground, we are relying on to
                process your personal information where more than one ground has
                been stated. We will rely on any of the legal bases listed below
                depending on the processing activities:
              </p>
              <ul className={styles.section__list}>
                <li>
                  We will ask you for your consent in certain processing
                  activities. Where we obtain your consent, kindly note that you
                  can withdraw the consent at any time, and we will comply by
                  not further processing your personal data.
                </li>
                <li>
                  We may process your personal data in order to fulfil the
                  contract you have entered with us or we have entered with your
                  company or take pre-contractual steps at your instruction.
                </li>
                <li>
                  We may rely on our legitimate interest to process your
                  personal data to tailor our services to suit your need.
                </li>
                <li>
                  We may process your personal data where law mandates us to do
                  so.
                </li>
              </ul>
            </div>
            <div>
              <p className={styles.section__ttl}>
                WHO DO WE SHARE YOUR PERSONAL DATA WITH?
              </p>
              <p className={styles.section__txt2}>
                We disclose your personal data to some vendors we have
                contractual relationship as provided below:
              </p>
              <ul className={styles.section__list}>
                <li>To enforcement agencies among others.</li>
                <li>
                  To our vendors, agents, and service providers that assist us
                  with certain services.
                </li>
                <li>To financial institutions to enable payment processing.</li>
                <li>
                  To judicial authorities where necessary to litigate, establish
                  and defend legal rights.
                </li>
                <li>To our legal counsel where the need arises.</li>
              </ul>
              <p className={styles.section__txt2}>
                We conduct data protection due diligence on our vendors to
                ensure that they are data protection compliant before entering
                into contractual relationship with them. We also bind them with
                confidentiality and data processing contracts to ensure that
                they implement appropriate technical and organizational measures
                to protect the personal data we share with them.
              </p>
            </div>
            <div>
              <p className={styles.section__ttl}>THIRD-PARTY LINKS</p>
              <p className={styles.section__txt2}>
                This notice applies to our site only and does not cover
                third-party links. Our site may include links to third-party
                websites, plug-ins and applications. Clicking on those links or
                enabling those connections may allow third parties to collect or
                share data about you. We do not control these third-party
                websites and are not responsible for their privacy notice. When
                you leave our website, we encourage you to read the privacy
                notice of every website you visit.
              </p>
            </div>
            <div>
              <p className={styles.section__ttl}>
                DO WE TRANSFER YOUR PERSONAL DATA OUTSIDE NIGERIA?
              </p>
              <p className={styles.section__txt2}>
                We transfer your personal data to United State of America and
                United Kingdom. The countries are included the Whitelist as
                countries with appropriate data protection law. Addition we have
                executed confidentiality and data protection processing
                agreement with our cloud service providers to the protection of
                personal data stored in their cloud server.  
              </p>
            </div>
            <div>
              <p className={styles.section__ttl}>DATA SECURITY</p>
              <p className={styles.section__txt2}>
                We have put appropriate security measures such as encryption,
                firewall, fingerprint identification and two factor
                authentication to prevent your personal information from being
                accidentally lost, used or accessed in an unauthorized way,
                altered or disclosed. In addition, we have business continuity
                plan that ensures a hot and cold backup of the data in our
                custody. Also, we limit access to your personal information to
                those agents, contractors and other third parties who need it to
                assist in providing services to products to you. They will only
                process your personal information on our instructions and they
                are subject to a duty of confidentiality.
              </p>
            </div>
            <div>
              <p className={styles.section__ttl}>DATA SECURITY</p>
              <p className={styles.section__txt2}>
                We will only retain your personal data for as long as reasonably
                necessary to fulfill the purposes they were collected and where
                we are required to assert or defend against legal claims,
                pending the final adjudication of the claim. In some
                circumstances we will anonymise your personal data so that it
                can no longer be associated with you for research or statistical
                purposes, in which case we may use this information indefinitely
                without further notice to you. We will securely destroy your
                personal information once we fulfil the purpose for which it was
                collected.
              </p>
            </div>
            <div>
              <p className={styles.section__ttl}>YOUR LEGAL RIGHTS</p>
              <p className={styles.section__txt2}>
                You have certain rights under the law which we respect and
                adhere to and which can be exercised by you at any time.
                However, note that these rights are not absolute and may only
                apply in certain circumstances. Your rights are as itemized:
              </p>
              <ul className={styles.section__list}>
                <li>
                  Request access to your personal information (commonly known as
                  a "data subject access request"). This enables you to receive
                  a copy of the personal information we hold about you and to
                  check that we are lawfully processing it.
                </li>
                <li>
                  Request correction of the personal information that we hold
                  about you. This enables you to have any incomplete or
                  inaccurate information we hold about you corrected.
                </li>
                <li>
                  Request erasure of your personal information. This enables you
                  to ask us to delete or remove personal information where there
                  is no good reason for us continuing to process it. You also
                  have the right to ask us to delete or remove your personal
                  information where you have exercised your right to object to
                  processing.
                </li>
                <li>
                  Object to processing of your personal information where we are
                  relying on a legitimate interest (or those of a third party)
                  and there is something about your particular situation which
                  makes you want to object to processing on this ground. You
                  also have the right to object where we are processing your
                  personal information for direct marketing purposes.
                </li>
                <li>
                  Request the restriction of processing of your personal
                  information. This enables you to ask us to suspend the
                  processing of personal information about you, for example if
                  you want us to establish its accuracy or the reason for
                  processing it.
                </li>
              </ul>
            </div>
            <div>
              <p className={styles.section__ttl}>WHAT WE MAY NEED FROM YOU?</p>
              <p className={styles.section__txt2}>
                We may need to request specific information from you to help us
                confirm your identity and ensure your right to access your
                personal information or to exercise any of your other rights.
                This is a security measure to ensure that personal data is not
                disclosed to any person who has no right to receive it. We may
                also contact you to ask you for further information in relation
                to your request to speed up our response.
              </p>
            </div>
            <div>
              <p className={styles.section__ttl}>RIGHT TO COMPLAINT</p>
              <p className={styles.section__txt2}>
                Kindly note that you have the right to make a complaint at any
                time to the Nigerian Data Protection Commission (“NDPC”). We
                would, however, appreciate the chance to deal with your concerns
                before you approach the Department of Justice, so please contact
                us in the first instance.
              </p>
            </div>
            <div>
              <p className={styles.section__ttl}>
                CHANGES TO OUR PRIVACY NOTICE
              </p>
              <p className={styles.section__txt2}>
                This Notice will be updated from time to time to reflect our
                processing activities and we will aim to notify you when this
                happens.
              </p>
            </div>
            <div>
              <p className={styles.section__ttl}>CONTACT DETAILS</p>
              <p className={styles.section__txt2}>
                Please address questions, comments and requests regarding this
                Notice to our DPO (dpo@zitrafundmanagers.com)
              </p>
            </div>
          </section>
          <GetInTouch className={styles.getInTouch} />
        </section>
      </section>
    </>
  );
};

export { PrivacyPolicyUI };
